import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { GlobalUrl } from 'src/app/global-url';
import { User } from '../entities/api/user';
import { Organisation } from '../entities/api/organisation';
import { GatewayMetaData } from '../entities/api/gateway';
import { OrganisationGatewayMetaData } from '../entities/api/organisationgatewaymetadata';
import { environment } from 'src/environments/environment';
import { Gateway } from '../../data-explorer/entities/api/gateway';

@Injectable({
    providedIn: 'root'
})
export class AdminService {

    constructor(private http: HttpClient,
        private url: GlobalUrl) { }

    private get<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.get<T>(this.url.badmintonUrl + partialUrl));
    }

    private post<T>(partialUrl: string, data: any): Promise<T> {
        return firstValueFrom(this.http.post<T>(this.url.badmintonUrl + partialUrl, data));
    }

    private put<T>(partialUrl: string, data: any): Promise<T> {
        return firstValueFrom(this.http.put<T>(this.url.badmintonUrl + partialUrl, data));
    }

    private delete<T>(partialUrl: string): Promise<T> {
        return firstValueFrom(this.http.delete<T>(this.url.badmintonUrl + partialUrl));
    }

    getAllOrganisations() {
        return this.get<Organisation[]>(`organisations`);
    }

    /**
      * Returns all groups for an organisation
    */
    getGatewayMetadata(organisation: Organisation) {
        return this.get<GatewayMetaData[]>(`organisations/${organisation.domain}/gatewaymetadata`);
    }
    /**
      * Returns all groups for an organisation
    */
    getAllGateways(organisation: Organisation) {
        return this.get<Gateway[]>(`organisations/${organisation.domain}/gateways`);
    }

    /**
      * Returns all users in an organisation
    */
    getAllUsers(organisation: Organisation) {
        return this.get<User[]>(`organisations/${organisation.domain}/users`);
    }

    /**
    * Creates user and returns the user name and id as part of the response
    */
    createUser(organisation: Organisation, user: User) {
        delete user.userId;
        return this.post<User>(`organisations/${organisation.domain}/users`, user);
    }

    /**
      * Delete user
    */

    deleteUser(organisation: Organisation, user: User) {
        return this.delete<User>(`organisations/${organisation.domain}/users/${user.email}`);
    }

    /**
      * Update user
    */

    updateUser(organisation: Organisation, user: User) {
        return this.put<User>(`organisations/${organisation.domain}/users/${user.email}`, user);
    }

    /**
    * Returns one organisation gateway meta data
    */
    getOrgGatewayMetaData(organisation: Organisation, gatewayId: number) {
        return this.get<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata/${gatewayId}`);
    }

    /**
    * Edits OrgGatewayMetaData and returns the OrgGatewayMetaData object as a response
    */
    editOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData) {
        return this.put<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata`, orgGatewayMetaData);
    }
    /**
    * Edits OrgGatewayMetaData and returns the OrgGatewayMetaData object as a response
    */
    addOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData) {
        return this.post<OrganisationGatewayMetaData>(`organisations/${organisation.domain}/gatewaymetadata`, orgGatewayMetaData);
    }

    addOrEditOrgGatewayMetaData(organisation: Organisation, orgGatewayMetaData: OrganisationGatewayMetaData, type: string) {
        if (type === 'Add') {
            return this.addOrgGatewayMetaData(organisation, orgGatewayMetaData);
        } else {
            return this.editOrgGatewayMetaData(organisation, orgGatewayMetaData);
        }
    }

    getAddressFromMapSearch(query: string) {
        return firstValueFrom(this.http.get<any>(`https://atlas.microsoft.com/search/address/json?&subscription-key=${environment.azureMapSubscriptionKey}&api-version=1.0&language=en-US&query=${query}`))
    }

    getAddressByLatAndLon(query) {
        return firstValueFrom(this.http.get<any>(`https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&subscription-key=${environment.azureMapSubscriptionKey}&language=en-US&query=${query}&number=1`))
    }

}
