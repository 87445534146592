import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'enumToOptions',
})
export class EnumToOptionsPipe implements PipeTransform {
    transform(enumObj: any): { label: string; value: number }[] {
        return Object.keys(enumObj)
            .filter(key => isNaN(Number(key)))
            .map(key => ({
                label: key.replace(/([a-z])([A-Z])/g, '$1 $2'),
                value: enumObj[key as keyof typeof enumObj],
            }));
    }
}